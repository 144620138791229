export async function sendEmailVehicle(revenda, nome, email, telefone, url_veiculo, mensagem, setState) {
    if (!revenda.peemail) {
        console.error("Erro: E-mail da revenda não encontrado.");
        setState({ enviado: true, msg_enviado: "Erro: Revenda sem e-mail cadastrado." });
        return;
    }

    const corpoMensagem = `
        Nome: ${nome}<br>
        E-mail: ${email}<br>
        Telefone: ${telefone}<br>
        Veículo: ${url_veiculo}<br>
        Mensagem: ${mensagem}
    `;

    const payload = {
        remetente: `Contato ${revenda.penome_fantasia} <${revenda.peemail}>`,
        assunto: `Interesse no veículo - Site ${revenda.penome_fantasia}`,
        destinatarios: revenda.peemail,
        corpo: corpoMensagem,
        corpoHtml: corpoMensagem
    };

    try {
        const response = await fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if (!response.ok) throw new Error("Erro ao enviar o e-mail");

        setState({
            enviado: true,
            msg_enviado: "E-mail enviado com sucesso!",
            nome: "",
            email: "",
            telefone: "",
            mensagem: ""
        });

    } catch (error) {
        console.error("Erro ao enviar e-mail:", error);
        setState({ enviado: true, msg_enviado: "Erro ao enviar e-mail, tente novamente mais tarde." });
    }

    setTimeout(() => setState({ enviado: false }), 5000);
}

export async function sendSellEmail(dados, setState) {
    try {
        setState({ enviado: true, msg_enviado: 'Enviando mensagem...' });

        const { nome, email, telefone, marca, modelo, versao, cor, km, placa, ano_modelo, opcionais, revenda } = dados;

        if (!revenda || !revenda.peemail) {
            throw new Error("Revenda sem e-mail cadastrado.");
        }

        const mensagem = `
            Nome: ${nome}<br>
            E-mail: ${email}<br>
            Telefone: ${telefone}<br><br>
            Marca: ${marca}<br>
            Modelo: ${modelo}<br>
            Versão: ${versao}<br>
            Cor: ${cor}<br>
            KM: ${km}<br>
            Placa: ${placa}<br>
            Ano/Modelo: ${ano_modelo}<br>
            Opcionais: ${opcionais}
        `;

        const response = await fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${revenda.penome_fantasia} <${revenda.peemail}>`,
                assunto: `Venda seu veículo - Site ${revenda.penome_fantasia}`,
                destinatarios: revenda.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        });

        if (!response.ok) {
            throw new Error("Falha ao enviar e-mail.");
        }

        setState({
            enviado: true,
            msg_enviado: 'E-Mail enviado com sucesso, em breve você receberá um retorno da nossa equipe.',
            nome: "",
            email: "",
            telefone: "",
            marca: '',
            modelo: '',
            versao: '',
            cor: '',
            km: '',
            placa: '',
            ano_modelo: '',
            opcionais: '',
        });

        setTimeout(() => setState({ enviado: false }), 5000);

    } catch (error) {
        console.error("Erro ao enviar e-mail:", error);
        setState({ enviado: true, msg_enviado: error.message || 'Erro ao enviar e-mail, tente novamente mais tarde.' });
        setTimeout(() => setState({ enviado: false }), 5000);
    }
}

export async function sendEmailFinancial(dados, setState) {
    try {
        setState({ enviado: true, msg_enviado: 'Enviando mensagem...' });

        if (!dados.revenda || !dados.revenda.peemail) {
            throw new Error("Revenda sem e-mail cadastrado.");
        }

        const formatMessage = (title, fields) => `
            <br><br><strong>${title}</strong><br>
            ${Object.entries(fields).map(([key, value]) => `<br>${key}: ${value}`).join('')}
        `;

        const mensagem = `
            ${formatMessage("Dados do financiamento", { "": dados.dados_financiamento })}
            ${formatMessage("Dados pessoais", {
                CPF: dados.cpf,
                Nome: dados.nome,
                "Data de nascimento": dados.data_nascimento,
                RG: dados.rg,
                Sexo: dados.sexo,
                "Estado civil": dados.estado_civil,
                "Naturalidade": `${dados.naturalidade} - ${dados.naturalidade_uf}`,
                Telefone: dados.telefone,
                Celular: dados.celular,
                "Nome do pai": dados.nome_pai,
                "Nome da mãe": dados.nome_mae,
                "CEP residencial": dados.cep_residencial,
                Endereço: `${dados.endereco}, ${dados.numero} ${dados.complemento}`,
                Bairro: dados.bairro,
                Cidade: `${dados.cidade} - ${dados.cidade_uf}`
            })}
            ${formatMessage("Dados comerciais", {
                "Empresa que trabalha": dados.empresa_trabalha,
                Cargo: dados.cargo,
                "Tempo de serviço": dados.tempo_servico,
                "CEP da empresa": dados.com_cep,
                Endereço: `${dados.com_endereco}, ${dados.com_numero} ${dados.com_complemento}`,
                Bairro: dados.com_bairro,
                Cidade: `${dados.com_cidade} - ${dados.com_uf}`
            })}
            ${formatMessage("Referências pessoais", {
                "Nome referência 01": dados.ref1_nome,
                "Telefone referência 01": dados.ref1_telefone,
                "Nome referência 02": dados.ref2_nome,
                "Telefone referência 02": dados.ref2_telefone
            })}
            ${formatMessage("Referências bancárias", {
                "Banco 01": dados.banco1_banco,
                "Agência banco 01": dados.banco1_agencia,
                "Conta banco 01": dados.banco1_conta,
                "Tempo de conta banco 01": dados.banco1_tempo_conta,
                "Banco 02": dados.banco2_banco,
                "Agência banco 02": dados.banco2_agencia,
                "Conta banco 02": dados.banco2_conta,
                "Tempo de conta banco 02": dados.banco2_tempo_conta
            })}
        `;

        const response = await fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${dados.revenda.penome_fantasia} <${dados.revenda.peemail}>`,
                assunto: `Simulação de Financiamento - Site ${dados.revenda.penome_fantasia}`,
                destinatarios: dados.revenda.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        });

        if (!response.ok) {
            throw new Error("Falha ao enviar e-mail.");
        }

        setState({
            enviado: true,
            msg_enviado: 'E-Mail enviado com sucesso, em breve você receberá um retorno da nossa equipe.',
            ...Object.keys(dados).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
        });

        setTimeout(() => setState({ enviado: false }), 5000);
    } catch (error) {
        console.error("Erro ao enviar e-mail:", error);
        setState({ enviado: true, msg_enviado: error.message || 'Erro ao enviar e-mail, tente novamente mais tarde.' });
        setTimeout(() => setState({ enviado: false }), 5000);
    }
}

export const sendEmailContact = (state, updateState) => {
    console.log(state);

    updateState({ enviado: true, msg_enviado: 'Enviando mensagem...' });

    const mensagem = `Nome: ${state.nome}<br>
        E-mail: ${state.email}<br>
        Telefone: ${state.telefone}<br>
        Veículo: ${state.url_veiculo}<br>
        Mensagem: ${state.mensagem}`;

    const destinatarios = [state.revenda?.peemail];

    const fetchPromises = destinatarios.map(email => {
        return fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: `Contato ${state.revenda?.penome_fantasia} <${state.revenda?.peemail}>`,
                assunto: `Interesse no veículo - Site ${state.revenda?.penome_fantasia}`,
                destinatarios: email,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        });
    });

    Promise.all(fetchPromises)
        .then(() => {
            updateState({
                enviado: true,
                msg_enviado: 'E-Mail Enviado com Sucesso',
                nome: "",
                email: "",
                telefone: "",
                mensagem: "",
            });
            setTimeout(() => updateState({ enviado: false }), 5000);
        })
        .catch(error => {
            updateState({
                enviado: true,
                msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde',
            });
            setTimeout(() => updateState({ enviado: false }), 5000);
        });
};